// Variables
// =============================================================================
// Default breakpoint map for include-media() mixin
// https://include-media.com/
$breakpoints: (
    xsmall : 26em, // 416px / phone @ portrait
    small  : 30em, // 480px / phone @ landscape / small tablet (portrait)
    medium : 48em, // 768px / small tablet (landscape) / large tablet (portrait)
    large  : 64em, // 1024px / large tablet (landscape) / small desktop
    xlarge : 80em, // 1280px / standard desktop
    xxlarge: 100em // 1600px / large desktop
);

$scrollbar-width: 5px;

$z-index: (
    progress    : 60,
    overlay     : 50,
    githubcorner: 40,
    navbar      : 30,
    coverpage   : 20,
    sidebar     : 10
);
